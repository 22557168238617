/*
 *
 * (C) Copyright 2023 by Homewood Health Inc.
 *
 *  $File: base.js
 *  $Purpose: Basic Javascript functions that are required to make the site work
 *
 */
import $ from 'jquery';
import * as bootstrap from 'bootstrap';

/**
 * Function: debug
 * @return: Wraps console functions so that they can be used without worrying about breaking browsers
 */
// let DEBUG_MODE = false;
export function debug() {
    if( typeof DEBUG_MODE !== 'undefined' && window.console ) {
        console.info( arguments );
    }
}// End of debug()

/**
 * Support Upper Casing Words in JS strings
 * @param str
 * @returns {string}
 */
export function ucwords(str) {
    if ( ! str ) { str = this; }

    return (str + '')
        .replace(/^(.)|\s+(.)/g, function ($1) {
            return $1.toUpperCase()
        })
}

String.prototype.ucwords = ucwords;

/**
 * UI
 * @purpose prototype to handle any UI elements
 */
window.UI = {
    init: function () {
        debug('UI: init: ');

        // Check for unread messages on page load and then poll for unread messages
        UI.checkUnreadMessages();

        // Check if the page is in an iFrame and add a class to reflect this
        if ( window.location !== window.parent.location ){
            $('html, body').addClass('in-frame');
        }

        // Check if the user agent is a mobile device and a safari browser. Then add a class to the body
        if (clientDetect.isMobile() === true && clientDetect.isSafari() === true) {
            // console.log('MOBILE SAFARI: ');
            $('body').addClass('page-mobile-safari');
        }

        // Check if the user agent is an iPad and a safari browser. Then add a class to the body
        if (clientDetect.isIpad() === true && clientDetect.isSafari() === true) {
            // console.log('MOBILE SAFARI: ');
            $('body').addClass('page-mobile-safari');
        }

        // Enable Bootstrap Popovers
        UI.applyPopover();
    },
    /**
     * @function applyPopover
     * @purpose Trigger a popover instance when the function is called.
     */
    applyPopover: function() {
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]', {
            trigger: 'focus'
        })
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
    },
    /**
     * @function hideAlertMessage
     * @purpose Hide a standard alert message
     *
     * @param element - the button element inside the alert that will trigger the message
     */
    hideAlertMessage: function( element ){
        // 1. Initialize variables
        let button = $(element);
        let alertContainer = button.parents('.alert');

        // 2. Hide the alert
        alertContainer.fadeOut(200);
    },
    /**
     * @function toggleItemView
     * @purpose Toggle between list vs grid view
     *
     * @param element - the button element will trigger grid vs list view
     */
    toggleItemView: function( element ){
        // 1. Initialize variables
        let button = $(element);
        let icon = button.find('#view-button-icon');
        let toggleButtonText = $('#view-button-text');
        let iconGrid = ('fa-grip');
        let iconList = ('fa-list');
        let tileItem = $('.item-digest');
        let tileClassGrid = 'item-grid';
        let collectionContainer = $('.container-collection');
        let collectionClassGrid = 'collection-grid';

        // 2. Toggle the button icon and text
        if ($(icon).hasClass(iconGrid)) {
            $(icon).removeClass(iconGrid).addClass(iconList);
            $(toggleButtonText).text('List View')
        } else {
            $(icon).removeClass(iconList).addClass(iconGrid);
            $(toggleButtonText).text('Grid View')
        }

        // 3. Toggle the manager collection-grid class
        collectionContainer.toggleClass(collectionClassGrid);

        // 4. Toggle the manager item-grid class
        tileItem.toggleClass(tileClassGrid);
    },
    /**
     * @function toggleTooltipPill
     * @purpose show and hide a tooltip when clicking on the button pill
     *
     * @param element
     */
    toggleTooltipPill: function( element ) {
        let button = $(element);
        let activeClass = 'active';
        let tooltipContainer = button.parents('.tooltip-pill');

        tooltipContainer.toggleClass(activeClass);
    },
    /**
     * @function toggleItemVisibility
     * @purose toggle the visibility of a child item
     *
     * @param element
     */
    toggleItemVisibility: function(element) {
        debug('toggleItemVisibility');
        // 1. Initialize variables
        let button = $(element);
        let parent = button.parents('.toggle-parent');
        let target = parent.children('.toggle-target');
        let visibleClass = 'hidden';

        // 2. Toggle the collapse class on the parent
        debug('parent:', parent.attr('class'));
        parent.toggleClass(visibleClass);

        // 3. Update the accessibility items
        if (parent.hasClass(visibleClass)){
            button.attr('aria-expanded', 'false');
        }
        else {
            button.attr('aria-expanded', 'true');
        }
    },
    /**
     * @function checkUnreadMessages()
     * @purpose Make an AJAX request to the API to see if there are any unread messages, if there are show a notification toast
     */
    checkUnreadMessages: function(){
        let messageToast = $('#msgToast');
        let messageNavLink = $('#msgLink');
        let request = null;
        let logoutMessage = 'Your session has expired, please sign in again.';

        if ( messageToast.length > 0 ){
            debug('UI.checkUnreadMessages: ');
            let endpoint = messageToast.data('endpoint');
            let logoutUrl = siteGlobals.logoutUrl + '?reason=' + encodeURIComponent(logoutMessage);

            if ( endpoint.length > 0 && endpoint !== '#' ){
                request = $.ajax({
                    url: endpoint,
                    method: 'POST',
                    timeout: 5000,
                    async: true,
                    beforeSend: ((request) => {
                       messageNavLink.find('.icon').addClass('icon-loading');
                    }),
                    statusCode: {
                        401: function( request ) {
                            debug('401: Request: ', request.statusCode, request.statusMessage, window.location.origin );
                            // Response was not JSON - log the user out
                            // location.assign(logoutUrl); - TODO: Enable this once the logout functionality works
                        },
                        403: function( request ) {
                            debug('403: Request: ', request.statusCode, request.statusMessage, window.location.origin );
                            // Response was not JSON - log the user out
                            // location.assign(logoutUrl); - TODO: Enable this once the logout functionality works
                        }
                    }
                });

                request.done((response, status, xhr ) => {
                    messageNavLink.find('.icon').addClass('icon-loading');

                    if ( request.getResponseHeader("Content-Type") === 'application/json' ){
                        if ( response.status === 'SUCCESS' ){
                            let payload = response.payload;
                            let linkElement = messageToast.children('.inner');
                            let iconElement = messageToast.find('.icon');
                            let countElement = messageToast.find('.count');
                            let dotElement = messageToast.find('.dot');
                            let textElement = messageToast.find('.text');

                            if ( typeof payload !== undefined ){
                                if ( payload.messageCount && payload.messageCount > 0 ) {
                                    // Show the notification
                                    messageToast.removeClass('d-none');
                                    messageToast.attr('aria-hidden', false);

                                    // Update the message count and add the animation class
                                    iconElement.addClass('ring');
                                    countElement.text(payload.messageCount);

                                    // If the number of unread messages will fit show them in the dot otherwise show '!'
                                    if (payload.messageCount <= 10) {
                                        dotElement.text(payload.messageCount);
                                    } else {
                                        dotElement.text('!');
                                    }

                                    // Show the appropriate text string - singular vs. plural
                                    if (payload.messageCount === 1) {
                                        textElement.text(textElement.data('text-singular'));
                                    } else {
                                        textElement.text(textElement.data('text-plural'));
                                    }

                                    // Update the aria label - TODO Implement this and the clear case in the block below
                                    linkElement.attr('aria-label', countElement.text() + ' ' + textElement.text());
                                }
                            }
                            else {
                                // Hide the notification element
                                messageToast.addClass('d-none');
                                messageToast.attr('aria-hidden', true);

                                // Reset the count, class and aria label
                                iconElement.removeClass('ring');
                                countElement.text('0');
                                linkElement.attr('aria-label',countElement.text() + ' ' + textElement.text() );
                            }

                            // Re-start the interval
                            UI.checkUnreadMessageInterval();
                        }
                        else {
                            console.log('Error checking for unread messages: ', response.message );
                        }
                    }
                    else {
                        // Response was not JSON - log the user out
                        console.log('Error checking for unread messages: unexpected payload - redirecting to log out');
                        // location.assign(logoutUrl); - TODO: Enable this once the logout functionality works
                    }
                });

                request.fail(function(request, status, error ){
                    messageNavLink.find('.icon').removeClass('icon-loading');
                    console.log('Error checking for unread messages: ', status, error, request );
                });
            }
        }
    },
    /**
     * @function checkUnreadMessageInterval
     * @purpose auto-poll for unread messages every minute
     */
    checkUnreadMessageInterval: function(){
        let messageToast = $('#msgToast');
        let checkMessageInterval = null;

        // Check for unread messages every minute
        if ( messageToast.length > 0 ) {
            checkMessageInterval = window.setTimeout(UI.checkUnreadMessages, 60000);
        }
    },
    /**
     * @function updateFrameSource
     * @purpose update the target iFrame to render the desired content
     *
     * @param url
     */
    updateFrameSource: function( url ){
        let frame = $('.content-frame');
        let src = url ? url : '#';

        // Set the src attribute of the iFrame to the new destination
        frame.attr('src', src);
    },
    /**
     * @function triggerClick
     * @purpose wrapper JS to trigger the click event on a specific element
     *
     * @param targetElement - The target element class or id with leading . or # before the class/id name
     */
    triggerClick: function( targetElement ) {
        $(targetElement).trigger('click');
    },
    /**
     * @function triggerSubmit
     * @purpose trigger a form submit for the nearest parent form element
     *
     * @param targetElement
     */
    triggerSubmit: function( targetElement ) {
        $(targetElement).parents('form').trigger('submit');
    },
    /**
     * @function updateDropdownText
     * @purpose trigger a form submit for the nearest parent form element
     *
     * @param element
     */
    updateDropdownText: function( element ){
        let dropdown = $(element).parents('.dropdown');
        let button = dropdown.find('.dropdown-toggle');

        button.text($(element).text());
    }
}; // End UI

/**
 * @prototype clientDetect
 * @purpose used to check certain client information like whether the platform is Windows, the browser is chrome/firefox or IE 10+
 *
 * @type {{isFirefox: (function(): boolean), isIpad: (function(): boolean), isIE: (function(): boolean), isWindows: (function(): boolean), isSafari: (function(): boolean), isMobile: (function(): boolean), isChrome: (function(): boolean)}}
 */
let clientDetect = {
    isWindows: function (){
        var result = false;
        if ( navigator.appVersion.indexOf("Win") !== -1 ) {
            result = true;
        }
        // console.log('isWindows: ', result);
        return result;
    },
    isChrome: function(){
        var result = false;
        if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
            result = true;
        }
        // console.log('isChrome: ', result);
        return result;
    },
    isFirefox: function(){
        var result = false;
        if(navigator.userAgent.indexOf("Firefox") !== -1 )  {
            result = true;
        }
        // console.log('isFirefox: ', result);
        return result;
    },
    isIE: function (){
        var result = false;
        if( ( navigator.userAgent.indexOf("MSIE") !== -1 )||( !!document.documentMode === true ) ){
            result = true;
        }
        // console.log('isIE: ', result);
        return result;
    },
    isSafari: function (){
        var result = false;
        if( navigator.userAgent.indexOf("Safari") !== -1 ){
            result = true;
        }
        // console.log('isSafari: ', result);
        return result;
    },
    isIpad: function (){
        var result = false;

        if( /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document ){
            result = true;
        }

        // var string = result ? 'is Ipad': 'not ipad';
        // console.log('isIpad: ' + string);
        return result;
    },
    isMobile: function (){
        var result = false;

        if ( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) ){
            result = true;
        }

        // var string = result ? 'is Mobile': 'not mobile';
        // console.log('isMobile: ' + string);
        return result;
    }
};// End of clientDetect
window.clientDetect = clientDetect;

/**
 * Document Ready
 */
$(document).ready(function ($) {
    debug( 'Document ready');

    // 1. Initialize UI elements
    UI.init();
});// End $(document).ready()
